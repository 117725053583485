<template>
  <div class="page-login">
    <div class="banner" :class="language=='cn'?'cn':''"></div>
    <div :class="mainwindow" >
      <div class="loginform">
        <div class="form-header">
          <span>{{loginFormTitle}}</span>
        </div>
        <el-form class="formpadding" :rules="rules" ref="login" :model="form">

            <el-form-item class="account-box" prop ="account">
              <el-input  :placeholder="accountPlaceholder" v-model="form.account" clearable>
                <i slot="prefix" class="iconfont icon-yonghuming"></i>
              </el-input>
            </el-form-item>
            <el-form-item class="password-box" prop="password">
              <el-input :placeholder="passwordPlaceholder" show-password v-model="form.password" clearable>
                <i slot="prefix" class="iconfont icon-mima"></i>
              </el-input>
            </el-form-item>
            <el-form-item class="captcha-box"  v-if="showCaptcha">
              <div id="captcha" ></div>
            </el-form-item>

          <el-checkbox class="check" :label="checkContent" v-model="saveAccount"></el-checkbox>
          <el-button type="primary" class="loginbutton" :disabled="submitDisabled" @click.native="onSubmit()">{{buttonText}}</el-button>

          <div class="bottom-buttons">
            <router-link to="/forget" class="link"  replace>{{forgetPassword}}</router-link>
            <router-link :to='registerUrl' class="link" replace>{{haveNoneAccount}}</router-link>
            <div class="link" @click="gotoPolicy()">{{policy}}</div>
            <!--
            <router-link to="https://www.cic-tp.com/en/protocol?id=SEC1810170927547812" class="link">{{policy}}</router-link>
            -->
          </div>
        </el-form>

      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import {mapMutations} from 'vuex'
  import utils from "../../../util/utils";
  const Appkey = 'FFFF0N00000000008E5C';
  const Scene = 'nvc_login';
  var _self;
  const _module='CTLogin';
  const _common="ctError";
export default {
  name: 'login',

  data() {
    const checkAccountRule=(rule,value,callback)=>{
      _self.checkAccount(rule,value,callback);
    }
    const checkPasswordRule=(rule,value,callback)=>{
      _self.checkPassword(rule,value,callback)
    }
    return {
      language:'en',
      loginUrl:'',

      loginFormTitle:'CIC Trade Pro',
      checkContent:'Keep me signed in on this computer',
      forgetPassword:'Forget your password?',
      haveNoneAccount:'Don\'t have an account?',
      policy:'Privacy Policy',
      buttonText:'Sign in',
      accountPlaceholder:'Please enter you acount or email',
      passwordPlaceholder:'Please enter you password',
      AliCheckError:'AliCheckError',
      // 数据校验
      accountIsNull:'User Name can not null',
      passwordIsNull:"Password can not null",

      // 数据
      firstNvc:null,
      saveAccount:false,
      showCaptcha:false,
      source:0,
      guidejs:'',
      registerUrl:'/register',

        ignoreSubmit:false,

      mainwindow :'mainwindow banner-ch',
      form:{
        account:'',
        password:''
      },
      rules:{
         account:[
           {validator:checkAccountRule,trigger:'blur'}
         ],
         password:[
           {validator:checkPasswordRule,trigger:'blur'}
         ]
      },
      submitDisabled:false,
    };
  },

  methods:{
    ...mapMutations([
      'setUserInfo'
    ]),
    gotoPolicy(){
        var lang = utils.getItem("languageCode");
        if(lang == "zh_cn"){
            window.open(this.$config.cicUrl+"/zh_cn/protocol?id=SEC1810170927547812");
            //this.language ="中文";
        } else {
            window.open(this.$config.cicUrl+"/en/protocol?id=SEC1810170927547812");
            //this.language ="English";
        }
      //window.location.href = "https://www.cic-tp.com/en/protocol?id=SEC1810170927547812"
    },
    checkAccount (rule,value,callback){
      if(value==''){
        callback(new Error(this.accountIsNull));
      } else {
        /*setTimeout(function () {
          callback(new Error("test"));
        },1000)*/
        callback();
      }
    },
    checkPassword(rule,value,callback){
      if(value==''){
        callback(new Error(this.passwordIsNull));
      } else {
        /*setTimeout(function () {
          callback(new Error("test"));
        },1000)*/
        callback();
      }
    },
    onSubmit() {
        if(this.ignoreSubmit)
            return;
      var that = this;
      this.$refs.login.validate(valid => {
        if (valid) {
          that.doAliWuhenLogin();
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    doAliWuhenLogin(){
      var params = new Object();

      params.nvcval=getNVCVal();
      this.doLogin(params)
    },
    set_ALY_config() {
      //var self = this;
      return new Promise((resolve) => {
        var nc_token = ["CF_APP_1", (new Date()).getTime(), Math.random()].join(':');
        window.NVC_Opt = {
          appkey: Appkey, // 开通无痕验证提供的appkey 与scene
          scene: Scene,
          token: nc_token,
          isH5: false,
          popUp: false,
          renderTo: '#captcha',
          nvcCallback: function(data) {
            // console.log(data);
            var params = new Object();
            //params.nvcval=getNVCVal();
            params.nvcval=data;
            _self.doLogin(params);
          },

          // trans: {"nvcCode":600, "key1": "code0"},

          language:this.language,
          //滑动验证长度配置
          customWidth: 300,
          customHeight: 40,
          //刮刮卡配置项
          width: 500,
          height: 125,
          elements: [
            "//img.alicdn.com/tfs/TB17cwllsLJ8KJjy0FnXXcFDpXa-50-74.png",
            "//img.alicdn.com/tfs/TB17cwllsLJ8KJjy0FnXXcFDpXa-50-74.png"
          ],
          bg_back_prepared: "//img.alicdn.com/tps/TB1skE5SFXXXXb3XXXXXXXXXXXX-100-80.png",
          bg_front: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABQCAMAAADY1yDdAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAADUExURefk5w+ruswAAAAfSURBVFjD7cExAQAAAMKg9U9tCU+gAAAAAAAAAIC3AR+QAAFPlUGoAAAAAElFTkSuQmCC",
          obj_ok: "//img.alicdn.com/tfs/TB1rmyTltfJ8KJjy0FeXXXKEXXa-50-74.png",
          bg_back_pass: "//img.alicdn.com/tfs/TB1KDxCSVXXXXasXFXXXXXXXXXX-100-80.png",
          obj_error: "//img.alicdn.com/tfs/TB1q9yTltfJ8KJjy0FeXXXKEXXa-50-74.png",
          bg_back_fail: "//img.alicdn.com/tfs/TB1w2oOSFXXXXb4XpXXXXXXXXXX-100-80.png",
          upLang:{"cn":{
              _ggk_guide: "请摁住鼠标左键，刮出两面盾牌",
              _ggk_success: "恭喜您成功刮出盾牌<br/>继续下一步操作吧",
              _ggk_loading: "加载中",
              _ggk_fail: ['呀，盾牌不见了<br/>请', "javascript:noCaptcha.reset()", '再来一次', '或', "http://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN", '反馈问题'],
              _ggk_action_timeout: ['我等得太久啦<br/>请', "javascript:noCaptcha.reset()", '再来一次', '或', "http://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN", '反馈问题'],
              _ggk_net_err: ['网络实在不给力<br/>请', "javascript:noCaptcha.reset()", '再来一次', '或', "http://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN", '反馈问题'],
              _ggk_too_fast: ['您刮得太快啦<br/>请', "javascript:noCaptcha.reset()", '再来一次', '或', "http://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN", '反馈问题']
            }
          },

        }
        resolve()
      })
    },

    doLogin(params) {
      params.loginName = this.form.account;
      params.password = this.form.password;
      params.source = this.source;
      params.isRememberTime = this.saveAccount?1:0;
      this.submitDisabled = true
      this.axios.post('/login',params).then(ret=>{
        _self.submitDisabled = false
        if(ret.status == 1){
          if(ret.data == 800){
              _self.ignoreSubmit = false;
            _self.$message.error(_self.AliCheckError);
              nvcReset();
              getNC().then(function () {
                  _nvc_nc.reset()
              })
          } else if(ret.data == 400){
              _self.ignoreSubmit = true;
            _self.showCaptcha = true;
            getNC().then(function () {
              _nvc_nc.reset()
            })
          } else {
              _self.ignoreSubmit = true;
            nvcReset();
            //console.log("login success:"+ret.data.token);
            _self.setUserInfo({
              token:ret.data.token
            });
            Vue.$cookies.set('token', ret.data.token);

            //_self.getUserInfo(ret.data.token);
            _self.$utils.removeItem('cicSource');
            window.sessionStorage.removeItem('clubInviteKey');
            if(_self.source == 1){
              if(_self.$route.query.redirect){
                window.location.href = _self.$route.query.redirect;
              }else{
                window.location.href = _self.$config.tradeUrl;
              }
            }else{
              let redirect = window.localStorage.getItem("CICLoginRedirect");
              if(redirect != null && redirect.length >0){
                window.localStorage.setItem("CICLoginRedirect","");
                window.location.href = redirect;
              }else {
                window.location.href =this.$config.cicUrl;
              }
            }
          }
        } else {
            _self.ignoreSubmit = false;
          if(ret.errorCode == "191"){
            _self.$message.error(_self.$t(_common+'.'+ret.errorMsg));
          } else if(ret.errorCode=="192"){
            //window.localStorage.setItem("emailKey",ret.errorMsg);
            window.location.href ="/#/mobileverify?key=" + ret.errorMsg;
          }else {
            _self.$message.error(_self.$t(_common+'.'+ret.errorMsg));
          }
        }
        //console.log(ret);
      }).catch(err=>{
        _self.submitDisabled = false
        console.log(err);
      })
    },
    // 动态加载js文件
    get_ALY_file() {
      try {
        var stamp = new Date().getTime();
        var script = document.createElement('script');
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('src', 'https://g.alicdn.com/sd/nvc/1.1.112/guide.js?t=' + stamp);
        document.documentElement.appendChild(script);
      } catch (err) {

          if(_self.$showLog)
        console.log(err);
      }
    },
    getUserInfo(token){
      _self.axios.post('/cicTrade/usercenter/accountinfo', {token:token}).then(ret=>{
        if(ret.status == 1){
          var redirect = window.localStorage.getItem("CICLoginRedirect");
          if(redirect != null && redirect.length >0){
            window.localStorage.setItem("CICLoginRedirect","");
            window.location.href = redirect;
          }else {
            window.location.href =this.$config.cicUrl;
          }
        }
      });
    },
    loginPost(){

    },

  },

  created() {

    _self = this;
    this.language = utils.getItem("languageCode") == 'zh_cn'? 'cn':'en';
    // this.language = this.language == 'zh_cn'? 'cn':'en';
    console.log(this.language)
    this.set_ALY_config().then(ret => {
        if(_self.$showLog)
      console.log(ret);
      this.get_ALY_file();
      setTimeout(function () {
        var  nvc = getNVCVal();
          if(_self.$showLog)
        console.log("nvc:"+nvc);
      },1000);
    });
  },
  mounted() {
      if (!utils.isPC()){
          this.$router.push({
              path: '/loginMobile',
              query: this.$route.query
          })
          return
      }

    _self = this;


    var obj = window.location.referer;
      if(_self.$showLog)
    console.log(obj);
    this.redirect = this.$route.query.redirect;
    if(this.$route.query.source){
      // this.source = this.$route.query.source;
      this.$utils.setItem("cicSource",this.$route.query.source);
    }
    if(this.$utils.getItem('cicSource')){
      this.source = this.$utils.getItem('cicSource')
    }
    this.registerUrl="/register?source="+this.source;
    if(this.redirect != null && this.redirect.length >0){
      window.localStorage.setItem("CICLoginRedirect",this.redirect);
    }
    var lang = utils.getItem("languageCode");
    if(lang == "zh_cn"){
      this.mainwindow ="mainwindow banner-ch";
    } else {
      this.mainwindow ="mainwindow banner-en";
    }
      this.loginFormTitle = this.$t(_module+'.loginFormTitle');
      this.checkContent = this.$t(_module+'.checkContent');
      this.forgetPassword = this.$t(_module+'.forgetPassword');
      this.haveNoneAccount = this.$t(_module+'.haveNoneAccount');
      this.policy = this.$t(_module+'.policy');
      this.buttonText = this.$t(_module+'.buttonText');
      this.accountPlaceholder = this.$t(_module+'.accountPlaceholder');
      this.passwordPlaceholder = this.$t(_module+'.passwordPlaceholder');
      this.AliCheckError = this.$t(_module+'.AliCheckError');
      this.accountIsNull = this.$t(_module+'.accountIsNull');
      this.passwordIsNull = this.$t(_module+'.passwordIsNull');


  },

}
</script>

<style lang="less" scoped>
  @import "../user.less";
  @import "./index.less";
</style>
<style lang="less">
  .mainwindow {
    .el-main{
      height: 100%;
    }

    .loginform{
      .el-form-item{
        margin: 0;
        padding-top: 40px;
      }
      .el-form-item__content{
        line-height: 40px;
      }
      .el-input__inner{
        padding-left: 55px;
        height: 40px;
        /*border: 1px solid #CAD3DE;*/
      }
      .el-input__prefix{
        left: 17px;
        .iconfont{
          color: #345380;
          font-size: 20px;
        }
      }

    }

    .loginform .el-checkbox__label{
      color: #81899E;
    }
    .loginform .el-checkbox__input.is-checked+.el-checkbox__label{
      color: #666666;
    }

    .nc-container .nc_wrapper, .nc-container.tb-login .nc_wrapper {
      width: 480px !important;
    }

    .nc-container .nc_scale{
      height: 40px;
    }
    .nc-container .nc_scale span{
      height: 48px !important;
      line-height: 48px !important;
    }
  }


</style>
